/**
 * Copyright 2018 Illumio, Inc. All Rights Reserved.
 */
import _ from 'lodash';
import intl from 'intl';
import React from 'react';
import actionCreators from '../actions/actionCreators';
import {Navigation} from 'react-router';
import {PolicyGeneratorMixin} from '../mixins';
import TrafficStore from '../stores/TrafficStore';
import MapPageStore from '../stores/MapPageStore';
import {RightClickActionUtils} from '../utils';
import {convertFiltersValuesToObject} from '../utils/RightClickActionUtils';

const emptyFilters = () => ({
  providerInclude: new Map(),
  providerExclude: new Map(),
  consumerInclude: new Map(),
  consumerExclude: new Map(),
  consumerOrProviderInclude: new Map(),
  consumerOrProviderExclude: new Map(),
  servicesInclude: new Map(),
  servicesExclude: new Map(),
  policyDecisions: new Map(),
});

export default React.createClass({
  mixins: [Navigation, PolicyGeneratorMixin],

  getConsumerProviderData(clicked, type) {
    const {data} = this.props.data.actions;
    const mapRoute = MapPageStore.getMapRoute();
    const loadConnectedGroups = JSON.parse(sessionStorage.getItem('loadConnectedGroups')) || [];
    const connectedGroupKey = [mapRoute.previd, mapRoute.id].join(',');
    const focusedAppGroup = TrafficStore.getAppGroupNode(mapRoute.previd);
    const connectedGroup = TrafficStore.getAppGroupNode(mapRoute.id);
    const info = data[type]?.data || data.data;

    let filterType = clicked === 'group' ? 'labelsAndLabelGroups' : clicked;
    let filterValues = [];

    if (data[type]?.data?.name !== 'discovered') {
      switch (clicked) {
        case 'group':
          // If this is an app group, or a group that is equal to an app group
          if (info.type === 'appGroup' || info.appGroupParent === info.href) {
            filterType = 'appgroups';
            filterValues = [
              {
                value: info?.name,
                detail: {
                  type: 'app_group',
                  href: info?.href,
                  labelsQuery: Object.values(info?.labels).map(label => ({label})),
                },
              },
            ];
            break;
          }

          filterValues = info?.labels;
          break;
        case 'appGroup':
          filterType = 'appgroups';

          // If only focused app group is selected
          if (!mapRoute.previd) {
            filterValues = [
              {
                value: data?.name,
                detail: {
                  type: 'app_group',
                  href: data?.href,
                  labelsQuery: Object.values(data?.labels).map(label => ({label})),
                },
              },
            ];
            break;
          }

          const selectedAppGroupType = data.connectionType;
          const focusedAppGroupType = mapRoute.type === 'consuming' ? 'target' : 'source';
          const connectedAppGroupType = mapRoute.type === 'consuming' ? 'source' : 'target';

          // When focused app group is selected and type is opposite of selection (consuming/providing)
          if (selectedAppGroupType === 'focused' && type === connectedAppGroupType) {
            filterValues = [
              {
                value: data?.name,
                detail: {
                  type: 'app_group',
                  href: data?.href,
                  labelsQuery: Object.values(data?.labels).map(label => ({label})),
                },
              },
              {
                value: connectedGroup?.name,
                detail: {
                  type: 'app_group',
                  href: connectedGroup?.href,
                  labelsQuery: Object.values(connectedGroup?.labels).map(label => ({label})),
                },
              },
            ];
            break;
          }

          // When consuming/providing is selected and type is opposite of selection (focused) and connected group is expanded
          if (
            loadConnectedGroups.includes(connectedGroupKey) &&
            (selectedAppGroupType === 'consuming' || selectedAppGroupType === 'providing') &&
            type === focusedAppGroupType
          ) {
            filterValues = [
              {
                value: data?.name,
                detail: {
                  type: 'app_group',
                  href: data?.href,
                  labelsQuery: Object.values(data?.labels).map(label => ({label})),
                },
              },
              {
                value: focusedAppGroup?.name,
                detail: {
                  type: 'app_group',
                  href: focusedAppGroup?.href,
                  labelsQuery: Object.values(focusedAppGroup?.labels).map(label => ({label})),
                },
              },
            ];
            break;
          }

          // When consuming/providing is selected and type is opposite of selection (focused) and connected group is not expanded
          if (
            !loadConnectedGroups.includes(connectedGroupKey) &&
            (selectedAppGroupType === 'consuming' || selectedAppGroupType === 'providing') &&
            type === focusedAppGroupType
          ) {
            filterValues = [
              {
                value: focusedAppGroup?.name,
                detail: {
                  type: 'app_group',
                  href: focusedAppGroup?.href,
                  labelsQuery: Object.values(focusedAppGroup?.labels).map(label => ({label})),
                },
              },
            ];
            break;
          }

          // All other cases
          filterValues = [
            {
              value: data?.name,
              detail: {
                type: 'app_group',
                href: data?.href,
                labelsQuery: Object.values(data?.labels).map(label => ({label})),
              },
            },
          ];
          break;

        case 'workload':
          if (info?.subType !== 'container' && type !== 'node') {
            filterValues = [
              {
                key: 'workloads',
                value: null,
                hostname: info?.name || info?.secondaryName,
                href: info?.href,
              },
            ];
            break;
          }

          if (['node', 'source', 'target'].includes(type)) {
            if (info?.subType === 'container') {
              filterType = 'container_workload';
            }

            filterValues = [
              {
                key: info?.subType === 'container' ? 'container_workloads' : 'workloads',
                value: null,
                hostname: info?.secondaryName || info?.name,
                href: info.href,
              },
            ];
            break;
          }

          filterValues = info?.labels;
          break;
        case 'role':
        case 'virtualServer':
        case 'virtualService':
          filterType = 'labelsAndLabelGroups';
          filterValues = info?.labels;
          break;
        case 'internet':
        case 'fqdn':
        case 'ipList':
          filterType = 'ip_list';
          filterValues = [
            {
              key: 'iplist',
              name: intl('IPLists.Any'),
              href: '/orgs/1/sec_policy/draft/ip_lists/1',
              value: [
                {from_ip: '0.0.0.0/0', exclusion: false},
                {from_ip: '::/0', exclusion: false},
              ],
            },
          ];
          break;
        default:
          filterValues = [];
      }
    }

    return filterValues.length ? {filterType, filterValues} : {};
  },

  transformIlluminationMapDataToIlluminationPlus() {
    const {data} = this.props.data.actions;
    const illuminationFilters = {};

    if (MapPageStore.getMapType() === 'loc' || MapPageStore.getMapType() === 'app') {
      const route = MapPageStore.getMapRoute();
      let type;

      if (data.type === 'group') {
        type = data.appGroup ? 'appGroup' : 'group';
      } else {
        type = data.type;
      }

      // Add an 'Or' filter for focused groups not on connected groups
      if (!data?.source && !data?.target && route.type !== 'consuming' && route.type !== 'providing') {
        illuminationFilters.consumerOrProviderInclude = new Map();

        const filterData = this.getConsumerProviderData(type, 'node');

        if (Object.keys(filterData).length) {
          illuminationFilters.consumerOrProviderInclude.set(filterData.filterType, filterData.filterValues);
        }
      } else {
        illuminationFilters.consumerInclude = new Map();
        illuminationFilters.providerInclude = new Map();

        const consumer = this.getConsumerProviderData(
          data?.source ? data?.source.type : type,
          data?.source || type === 'appGroup' ? 'source' : 'node',
        );

        if (Object.keys(consumer).length) {
          illuminationFilters.consumerInclude.set(consumer.filterType, consumer.filterValues);
        }

        const provider = this.getConsumerProviderData(
          data?.target ? data?.target.type : type,
          data?.target || type === 'appGroup' ? 'target' : 'node',
        );

        if (Object.keys(provider).length) {
          illuminationFilters.providerInclude.set(provider.filterType, provider.filterValues);
        }
      }
    }

    illuminationFilters.time = JSON.parse(localStorage.getItem('mapFilterState'))?.time || {
      label: intl('Explorer.LastHours', {count: 24}),
      explorerValue: intl('Explorer.LastDays', {count: 1}),
      value: 'day',
    };

    const filters = convertFiltersValuesToObject({...emptyFilters(), ...illuminationFilters});

    localStorage.setItem('mapFilterState', JSON.stringify(filters));

    return filters;
  },

  /* Action from the actionCreators that must be called on different clicks */
  handleClick(action, data) {
    let location;
    let clusters;

    // set viewRuleComponent and viewRuleComponentType for both viewRule and viewEnforcementBoundary
    const {type} = this.props;
    let viewRuleComponent = [];
    let viewRuleComponentType;

    if (type === 'location') {
      viewRuleComponent = [
        {
          type: 'location',
          href: data.href,
        },
      ];

      viewRuleComponentType = 'location';
    } else if (this.props.type === 'cluster') {
      viewRuleComponentType = MapPageStore.getMapType() === 'app' ? 'appGroup' : 'group';
      viewRuleComponent = [{type: viewRuleComponentType, href: data.href, clusterId: data.clusterId}];
    } else if (this.props.type === 'link') {
      const linkSource = data.source;
      const linkTarget = data.target;
      const clusterHref =
        linkSource.cluster && linkTarget.cluster && linkSource.cluster.href === linkTarget.cluster.href
          ? linkSource.cluster.href
          : null;

      if (data.internetLinks) {
        viewRuleComponent = _.map(data.internetLinks, link => ({
          type: link.type,
          href: link.href,
          identifier: data.identifier,
          clusterHref,
        }));
      } else {
        viewRuleComponent = [
          {
            type: 'traffic',
            href: data.href,
            identifier: data.identifier,
            clusterHref,
          },
        ];
      }

      viewRuleComponentType = 'traffic';
    }

    switch (action.actionType) {
      case 'expandRoleAction':
        actionCreators.updateGraphCalculated();
        actionCreators.expandRole(action.expandRoleAction.data);
        break;
      case 'collapseRoleAction':
        actionCreators.updateGraphCalculated();
        actionCreators.collapseRole(action.collapseRoleAction.data);
        break;
      case 'zoomToFit':
        actionCreators.updateZoomToFit(data);
        break;
      case 'resetLayout':
        actionCreators.resetLayout(data);
        break;
      case 'viewRulesets':
        switch (this.props.type) {
          case 'location':
            const filter = {labelsAndLabelGroups: [{key: 'loc', value: data.name, href: data.href}]};

            this.transitionTo('rulesets.list', null, {
              rulesetlist: JSON.stringify({filter}),
            });

            break;
          case 'cluster':
            if (MapPageStore.getMapType() === 'loc') {
              this.transitionTo('groupRules', {id: data.href});
            } else {
              this.transitionTo('appGroupRules', {id: data.href});
            }

            break;
        }

        break;
      case 'viewWorkloads':
        switch (this.props.type) {
          case 'location':
            const labelsAndLabelGroups = [{key: 'loc', href: data.href, value: data.value}];

            const params = {
              workloadlist: JSON.stringify({filter: {labelsAndLabelGroups}}),
            };

            this.transitionTo('workloads.list', null, params);
            break;
          case 'cluster':
            if (MapPageStore.getMapType() === 'loc') {
              this.transitionTo('groupWorkloads', {id: data.href});
            } else {
              this.transitionTo('appGroupWorkloads', {id: data.href});
            }

            break;
        }

        break;

      case 'viewContainerWorkloads':
        const viewContainerWorkloadsPath = this.props.data.actions.data.appGroup
          ? 'appGroupContainerWorkloads'
          : 'groupContainerWorkloads';
        const viewContainerWorkloadsParams = {id: this.props.data.actions.data.href};

        this.transitionTo(viewContainerWorkloadsPath, viewContainerWorkloadsParams);
        break;

      case 'viewVirtualServices':
        const viewVirtualServicesPath = this.props.data.actions.data.appGroup
          ? 'appGroupVirtualServices'
          : 'groupVirtualServices';
        const viewVirtualServicesParams = {id: this.props.data.actions.data.href};

        this.transitionTo(viewVirtualServicesPath, viewVirtualServicesParams);
        break;

      case 'addRule':
        let component = [];
        let componentType;

        if (this.props.type === 'location') {
          component = [
            {
              type: data.type,
              href: data.href,
            },
          ];
          componentType = 'location';
        } else if (this.props.type === 'cluster') {
          if (MapPageStore.getMapType() === 'app') {
            component = [
              {
                type: 'appGroup',
                href: data.href,
                clusterId: data.clusterId,
              },
            ];
            componentType = 'appGroup';
          } else {
            component = [
              {
                type: 'group',
                href: data.href,
                clusterId: data.clusterId,
              },
            ];
            componentType = 'group';
          }
        } else if (this.props.type === 'link') {
          const linkSource = data.source;
          const linkTarget = data.target;
          const clusterHref =
            linkSource.cluster && linkTarget.cluster && linkSource.cluster.href === linkTarget.cluster.href
              ? linkSource.cluster.href
              : null;

          if (data.internetLinks) {
            component = _.map(data.internetLinks, link => ({
              type: link.type,
              href: link.href,
              identifier: data.identifier,
              clusterHref,
            }));
          } else {
            component = [
              {
                type: 'traffic',
                href: data.href,
                identifier: data.identifier,
                clusterHref,
              },
            ];
          }

          componentType = 'traffic';
        }

        if (!data.selected) {
          actionCreators.updateComponentSelection(component);
        }

        // If there is one connection. send in this connection else if there is more than one connection send in "All Services".
        if (componentType === 'traffic') {
          const connections = Object.values(data.connections);
          const services = connections.length === 1 ? connections[0] : 'allServices';

          actionCreators.selectPbUb({
            pbub: 'pb',
            type: data.target.type,
            href: data.target.href,
            labels: data.target.labels,
          });
          actionCreators.selectPbUb({
            pbub: 'ub',
            type: data.source.type,
            href: data.source.href,
            labels: data.source.labels,
          });
          actionCreators.selectService(services);
        }

        actionCreators.clickActionItem({
          type: 'addRuleAction',
          entityType: componentType,
        });
        break;
      case 'viewRules':
        if (!data.selected) {
          // Updates which component is selected.
          actionCreators.updateComponentSelection(viewRuleComponent);
        }

        actionCreators.clickActionItem({
          type: 'viewRulesAction',
          entityType: viewRuleComponentType,
        });
        break;
      case 'viewRule':
        if (!data.selected) {
          // Updates which component is selected.
          actionCreators.updateComponentSelection(viewRuleComponent);
        }

        actionCreators.clickActionItem({
          type: 'viewRuleAction',
          entityType: viewRuleComponentType,
        });
        break;
      case 'viewEnforcementBoundaries':
        if (!data.selected) {
          // Updates which component is selected.
          actionCreators.updateComponentSelection(viewRuleComponent);
        }

        actionCreators.clickActionItem({
          type: 'viewEnforcementBoundariesAction',
          entityType: viewRuleComponentType,
        });
        break;
      case 'searchGroups':
        location = [
          {
            type: data.type,
            href: data.href,
          },
        ];

        actionCreators.updateComponentSelection(location); // Updates which location component is selected.
        actionCreators.clickActionItem({
          //Renders the command panel form holding the search group functionality.
          type: 'findGroupAction',
          entityType: 'location',
        });
        break;
      case 'viewPolicyMap':
        actionCreators.setAppMapVersion('policy');

        let nextPolMapRoute = {};

        if (
          data.connectionType === 'consuming' ||
          data.connectionType === 'providing' ||
          data.connectionType === 'focused' ||
          data.displayType === 'token'
        ) {
          nextPolMapRoute = {
            type: 'focused',
            id: data.href,
          };
        } else if (
          (data.type === 'group' && (data.displayType === 'summary' || data.displayType === 'full')) ||
          data.connectionType === 'location'
        ) {
          nextPolMapRoute = {
            type: 'focused',
            id: data.appGroupParent,
          };
        }

        this.transitionTo('appMapLevel', nextPolMapRoute);
        break;
      case 'viewVulnerabilityMap':
        actionCreators.setAppMapVersion('vulnerability');

        let nextVulMapRoute = {};

        if (
          data.connectionType === 'consuming' ||
          data.connectionType === 'providing' ||
          data.connectionType === 'focused'
        ) {
          nextVulMapRoute = {
            type: 'focused',
            id: data.href,
          };
        } else if (
          (data.type === 'group' && (data.displayType === 'summary' || data.displayType === 'full')) ||
          data.connectionType === 'location'
        ) {
          nextVulMapRoute = {
            type: 'focused',
            id: data.appGroupParent,
          };
        }

        this.transitionTo('appMapLevel', nextVulMapRoute);
        break;
      case 'viewPolicyGenerator':
        const appGroupForPolicyGenerator =
          MapPageStore.getMapType() === 'loc'
            ? TrafficStore.getNodeForPolicyGenerator(data.appGroupParent)
            : TrafficStore.getNodeForPolicyGenerator(data.href);

        this.setAppGroup(appGroupForPolicyGenerator);
        this.transitionTo('policygenerator');
        break;
      case 'viewConnectedGroups':
        if (data.displayType === 'token' || data.displayType === 'summary') {
          const currentLevel = MapPageStore.getMapLevel();
          const mapLevel = {
            type: data.labels.loc || currentLevel === 'workload' ? 'group' : 'full',
            id: data.href !== 'discovered' && !data.labels.loc ? 'nolocation' : data.href,
          };

          this.props.updateMapLevel(mapLevel);
        }

        clusters = [
          {
            type: 'group',
            href: data.href,
            clusterId: data.clusterId,
          },
        ];

        actionCreators.updateComponentSelection(clusters); //Renders the command panel form for the add Rule functionality
        actionCreators.clickActionItem({
          //Renders the command panel form holding the view connected group functionality.
          type: 'findConnectedGroupAction',
        });
        break;
      case 'expandTrafficLinks':
        const currentLevel = MapPageStore.getMapLevel();
        const currentRoute = MapPageStore.getMapRoute();

        switch (`${data.source.type}|${data.target.type}`) {
          case 'group|group':
            RightClickActionUtils.expandGroupLink(data, currentLevel, currentRoute);
            break;
          case 'role|role':
          case 'internet|role':
          case 'role|internet':
          case 'ipList|role':
          case 'role|ipList':
          case 'workload|role':
          case 'role|workload':
          case 'role|fqdn':
          case 'fqdn|role':
            RightClickActionUtils.expandRoleLink(data);
            break;
        }

        break;
      case 'collapseTrafficLinks':
        actionCreators.updateGraphCalculated();

        if (data.source.roleParent) {
          actionCreators.collapseRole([data.source.roleParent]);
        }

        if (data.target.roleParent) {
          actionCreators.collapseRole([data.target.roleParent]);
        }

        break;
      case 'viewConsumingAppGroups':
        clusters = [
          {
            type: 'appGroup',
            href: data.href,
            clusterId: data.clusterId,
          },
        ];

        actionCreators.updateComponentSelection(clusters);
        actionCreators.clickActionItem({
          type: 'findConsumingGroupAction',
        });
        break;
      case 'viewProvidingAppGroups':
        clusters = [
          {
            type: 'appGroup',
            href: data.href,
            clusterId: data.clusterId,
          },
        ];

        actionCreators.updateComponentSelection(clusters);
        actionCreators.clickActionItem({
          type: 'findProvidingGroupAction',
        });
        break;
      case 'viewIlluminationPlus':
        this.transformIlluminationMapDataToIlluminationPlus();
        this.transitionTo('illumination', null, {go: true, display: 'table'});
        break;
      case 'mitigateVulnerability':
        const appGroupFormitigatingVul = TrafficStore.getNodeForPolicyGenerator(data.href);

        this.setAppGroup(appGroupFormitigatingVul);
        this.transitionTo('policygenerator');
        break;
    }
  },

  menuoptions(actions, data) {
    const menuList = actions.map(a =>
      a.text ? (
        <li
          className="menu-option"
          key={`menu-option-${a.text}`}
          onClick={_.partial(this.handleClick, a, data)}
          data-tid={`action-item-${_.snakeCase(a.text).replace(/_/gi, '-')}`}
        >
          <span className="menu-option-text"> {a.text} </span>
        </li>
      ) : null,
    );

    return menuList;
  },

  render() {
    const {primaryAction, secondaryAction, tertiaryAction, data} = this.props.data.actions;
    let secondaryMenu = null;
    let tertiaryMenu = null;
    let primaryMenu = null;

    if (primaryAction[0]) {
      primaryMenu = <ul className="menu-options primary">{this.menuoptions(primaryAction, data)}</ul>;
    }

    if (!primaryAction[0] && secondaryAction[0]) {
      secondaryMenu = <ul className="menu-options primary">{this.menuoptions(secondaryAction, data)}</ul>;
    } else if (secondaryAction[0]) {
      secondaryMenu = <ul className="menu-options secondary">{this.menuoptions(secondaryAction, data)}</ul>;
    }

    if (tertiaryAction[0]) {
      tertiaryMenu = <ul className="menu-options secondary">{this.menuoptions(tertiaryAction, data)}</ul>;
    }

    return (
      <div className="menu">
        {primaryMenu}
        {secondaryMenu}
        {tertiaryMenu}
      </div>
    );
  },
});
