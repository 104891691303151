/**
 * Copyright 2014 Illumio, Inc. All Rights Reserved.
 */
import React from 'react';
import ReactDOM from 'react-dom';
import Router from 'react-router';
import Visibility from 'visibilityjs';
import {UNAUTHORIZED} from 'http-status-codes';
import {SessionStore} from './stores';
import Routes, {RoutesMap} from './Routes.jsx';
import {login} from './stores/SessionStore';
import Session from './lib/session';
import * as progressBar from 'progressBar';
import RestApiUtils from './utils/RestApiUtils';
import ProvisionUtils from './utils/ProvisionUtils';
import GeneralUtils from './utils/GeneralUtils';
import * as webStorageUtils from 'utils/webStorage';
import {setRoleNames, setHostIsSupercluserMember} from './lib/api';
import intl, {setIntlValueMapper, setIntlKeyMapper} from 'intl';
import {getIntlValueMapper, getIntlKeyMapper} from 'intl/utils';
import actionCreators from './actions/actionCreators';
import tesseReactRouteNamesMap from './components/InstantSearch/InstantSearchContainerProperties';
import {GraphDataUtils} from './utils';

const intlValuesMappingForEdge = {
  [intl('Common.Workloads')]: intl('Edge.Endpoints'),
  [intl('Common.Workload')]: intl('Edge.Endpoint'),
  [intl('Common.IPList')]: intl('Common.IPRange'),
  [intl('Common.IPLists')]: intl('IPLists.Mixin.Ranges'),
  [intl('Illumio.About.Core')]: intl('Illumio.About.Edge'),
  [intl('Common.Consumers')]: intl('Common.Sources'),
  [intl('Common.Providers')]: intl('Common.Destinations'),
  [intl('Common.Provider')]: intl('Common.Destination'),
  [intl('Common.Consumer')]: intl('Common.Source'),
  [intl('Help.Desc.IPList')]: intl('Help.Desc.IPRange'),
  [intl('Help.Desc.Providers')]: intl('Help.Desc.Destination'),
  [intl('Help.Desc.Consumers')]: intl('Help.Desc.Source'),
  [intl('Common.Provided')]: intl('Edge.Provided'),
  [intl('Common.Consumed')]: intl('Edge.Consumed'),
};

const intlKeysMappingForEdge = {
  'Workloads.EnforcementBoundary': 'Edge.DenyRule',
  'Workloads.EnforcementBoundaries': 'Rulesets.DenyRules',
  'Common.ConsumerAndProvider': 'Common.SourcesAndDestinations',
  'Common.ConsumerOrProvider': 'Common.SourcesOrDestinations',
  'Common.ProviderAndConsumer': 'Common.DestinationsAndSources',
  'Common.ProviderOrConsumer': 'Common.DestinationsOrSources',
  'Explorer.ClearFilters': 'Explorer.ResetToDefault',
  'Explorer.ProviderNames': 'Explorer.DestinationNames',
  'Explorer.ProviderIPAddresses': 'Explorer.DestinationIPAddresses',
  'Common.ProviderFqdn': 'Common.DestinationFqdn',
  'Explorer.ConsumerNames': 'Explorer.SourceNames',
  'Explorer.ConsumerIPAddresses': 'Explorer.SourceIPAddresses',
  'Explorer.ProviderLabels': 'Explorer.DestinationGroups',
  'Explorer.ConsumerLabels': 'Explorer.SourceGroups',
  'Explorer.ConsumerSelections': 'Explorer.SourceSelections',
  'Explorer.ProviderSelections': 'Explorer.DestinationSelections',
  'Help.Desc.ExplorerSearchFormats': 'Edge.Help.Desc.ExplorerSearchFormats',
  'PairingProfiles.UnlimitedWorkloadCanPaired': 'Edge.InstallScript.UnlimitedEnpointsCanBePaired',
  'PairingProfiles.OneWorkloadCanPaired': 'Edge.InstallScript.OneEndpointCanBePaired',
  'PairingProfiles.UnlimitedTime': 'Edge.InstallScript.UnlimitedTime',
  'Port.PortProcess': 'Edge.Port.PortProcess',
  'Common.NotConsumed': 'Edge.NotConsumed',
  'Explorer.SourceInclude': 'Edge.Explorer.SourceInclude',
  'Explorer.TargetInclude': 'Edge.Explorer.TargetInclude',
  'Explorer.OrIncludeCP': 'Edge.Explorer.OrIncludeCP',
  'Explorer.OrIncludePC': 'Edge.Explorer.OrIncludePC',
  'Explorer.OrExcludeCP': 'Edge.Explorer.OrExcludeCP',
  'Explorer.OrExcludePC': 'Edge.Explorer.OrExcludePC',
  'Explorer.SourceExclude': 'Edge.Explorer.SourceExclude',
  'Explorer.TargetExclude': 'Edge.Explorer.TargetExclude',
  'GlobalNetwork.CorporateNatPublicIpsDesc': 'Edge.GlobalNetwork.CorporateNatPublicIpsDesc',
  'Common.AppGroupsMore': 'Common.GroupsMore',
  'Common.EntitiesIsNotMore': 'Common.ExcludeWorkload',
  'Common.RFC1918': 'Common.RFC1918Private',
  'Common.ServiceIsNot': 'Common.ExcludeService',
  'Common.ServiceIsNotMore': 'Common.ExcludeServiceMore',
  'IPLists.EnableValidation': 'IPLists.EnableValidationIPs',
  'IPLists.EnableValidationTooltip': 'IPLists.EnableValidationTooltipIps',
  'Policy.IdleDesc': 'Policy.IdleDescIllumio',
  'Firewall.Coexistence.ModeDesc': 'Firewall.Coexistence.ModeDescEdge',
  'VEN.PairWithPairingProfile': 'VEN.PairVENWithPairingProfile',
};

(async () => {
  let user;

  try {
    if (Visibility.state() === 'prerender') {
      // If document is prerendering (like in Safari) before user actually pressed enter on the url,
      // wait for any other visibility state to actually start fetching data.
      // It's ok to fetch and render on hidden page, for instance if user opened new tab in background (like cmd+enter)
      await new Promise(resolve => Visibility.afterPrerendering(resolve));
    }

    // Try to get user from tessereact jump first. #USERJUMP
    user = webStorageUtils.getSessionItem('JUMPS_USER', {remove: true});

    if (user) {
      login(user);
    } else {
      try {
        // Try to login user on PCE with or without authToken
        // If without, browser will send pce_session cookie, that we can't read from javascript (it is HttpOnly)
        user = (await RestApiUtils.users.loginToPCE(window.authToken)).body;
      } catch (error) {
        // If user session does not exist, save url and redirect to login server
        // 401
        if ([UNAUTHORIZED].includes(error.response.status)) {
          Session.clear();
          Session.saveUriForLoginRedirect();

          localStorage.removeItem('user_id');

          window.location = error.response.headers.get('x-redirect') || _loginHref_;

          return; // Because redirect is not instant
        }

        throw error;
      }
    }

    if (SessionStore.isEdge()) {
      setIntlValueMapper(getIntlValueMapper(intlValuesMappingForEdge));
      setIntlKeyMapper(getIntlKeyMapper(intlKeysMappingForEdge));
    }

    actionCreators.setRoutesMap(new Map([...RoutesMap.entries(), ...Object.entries(tesseReactRouteNamesMap())]));

    // Edge version temporary workaround
    // Will use release_info from "product_version" object for checking both parameterized and non-parameterized build for
    // backend with Edge until backend is ready to use 'user.product.version'.
    // Note: Prior parameterized builds before 6/10/2020 have release_info: 'parameterized'.
    // e.g. {release_info: '20.1.0-Edge'} - non-parameterized build
    //      {release_info: '0.0.0-parameterized' - parameterized build
    //      {release_info: 'parameterized' - previous parameterized build before 6/10/2020
    const regex = /^(\d+\.\d+\.\d+).*/;

    if (regex.test(user.product_version.release_info)) {
      user.product_version.version = user.product_version.release_info.match(regex)[1];
    }

    setRoleNames(Session.getRoleNames());
    setHostIsSupercluserMember(SessionStore.isSuperclusterMember());
  } catch {
    // In case of any other error (not 401) redirect to the root, so tessereact can handle it and show a message
    window.location = '#/';
    location.reload(false);

    return; // Because redirection is not instant
  }

  // check version mismatch
  if (
    GeneralUtils.getVersionMismatch({
      pceVersion: user.product_version.version,
      parameterized: user.product_version.parameterized,
    }) !== 0
  ) {
    window.location = '#/versionmismatch';
    // need to reload(false) to reload from cache since a hash is declared for window.location = '#/versionmismatch'
    location.reload(false);

    // Because redirection is not instant
    return;
  }

  await RestApiUtils.orgSettings.get();

  if (!__ANTMAN__ && SessionStore.isLabelSettingsEnabled()) {
    await RestApiUtils.labels.getLabelSettingsCollection();
  }

  // Wait
  const [settingsResponse] = await Promise.all([
    RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'settings'),
    RestApiUtils.optionalFeatures.getCollection(),
    RestApiUtils.orgs.getInstance(Session.getOrgId()),
    RestApiUtils.health.get(),
    RestApiUtils.secPolicies.getCollection(),
    RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'whats_new'),
    RestApiUtils.vens.getEndpointVENCount(),
    RestApiUtils.ruleSets.getPCEUpgradeRuleset(),
  ]);

  if (!settingsResponse?.body) {
    // if there are no settings in kvpairs yet, make a call to create them once
    RestApiUtils.kvPair.update(SessionStore.getUserId(), 'settings', {});
  }

  RestApiUtils.kvPairs.getInstance(SessionStore.getUserId(), 'instant_search_history').then(historyResponse => {
    if (!historyResponse?.body) {
      RestApiUtils.kvPair.update(SessionStore.getUserId(), 'instant_search_history', []);
    }
  });

  if (SessionStore.isCoreServicesSettingsEnabled()) {
    RestApiUtils.coreServices.getSettings();
  }

  if (SessionStore.isUIAnalyticsEnabled() && !__DEV__) {
    const loginUrl = new URL(user.login_url);

    try {
      import(/* webpackChunkName: 'analytics' */ 'utils/analytics').then(({default: loadDAPAnalytics}) => {
        loadDAPAnalytics(user, loginUrl);
      });
    } catch (error) {
      console.error(`Error attempting to initialize DAP analytics: ${error}`);
    }

    try {
      import(/* webpackChunkName: 'logrocket' */ 'logrocket').then(({default: LogRocket}) => {
        // Setup analytics
        const appID = __ANTMAN__ ? 'rgjofh/masked-commercial-staging' : `rgjofh/${__TARGET__.toLowerCase()}-production`;

        LogRocket.init(appID, {
          shouldCaptureIP: false,
          ...(!__ANTMAN__ && {
            dom: {
              isEnabled: true,
              inputSanitizer: true,
              textSanitizer: true,
            },
          }),
          network: {
            isEnabled: false,
          },
        });

        // Since Org IDs + User IDs are not guaranteed to be unique between clusters
        // lets tie the hostname to the org ID and user ID.
        LogRocket.identify(user.id, {
          orgId: user.org_id,
          hostname: loginUrl.hostname,
        });
      });
    } catch (error) {
      console.error(`Error attempting to initialize analytics: ${error}`);
    }
  }

  // Start but don't wait
  RestApiUtils.nfcs.getCollection();
  ProvisionUtils.provisionCheckPending();
  RestApiUtils.secPolicies.dependencies();
  RestApiUtils.venReleases.getCollection();

  if (SessionStore.isUserScoped() && !GraphDataUtils.isAppGroupSummaryLoaded(true)) {
    GraphDataUtils.getTraffic('appGroup', {route: 'groups'});
  }

  progressBar.end();

  const render = Element => ReactDOM.render(Element, document.querySelector('#root')); // eslint-disable-line react/no-render-return-value

  if (!__DEV__) {
    Router.run(Routes, Handler => render(React.createFactory(Handler)({})));
  } else {
    const RedBox = require('redbox-react').default;
    let renderError;

    const run = Routes => {
      Router.run(Routes, Handler => {
        requestAnimationFrame(() => {
          try {
            // _rootComponentInstance_ is needes for invoking forceUpdate after hot intl replacement occured (when you change language file)
            window._rootComponentInstance_ = render(React.createFactory(Handler)({}));

            if (renderError) {
              // If there is no render error anymore, reload the page (can't correctly restore state now after error)
              location.reload();
            }
          } catch (error) {
            renderError = error;
            render(<RedBox error={error} />);
          }
        });
      });
    };

    run(Routes);

    if (module.hot) {
      module.hot.accept('./Routes.jsx', () => {
        requestAnimationFrame(() => {
          const Routes = require('./Routes.jsx').default;

          run(Routes);
        });
      });
    }
  }
})();
